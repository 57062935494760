import '../styles/styles.css'
import '../styles/fonts.css'
import type { AppProps } from 'next/app'
import { ReactElement, ReactNode, useEffect } from 'react'
import type { NextPage } from 'next'
import { QueryClient, QueryClientProvider } from 'react-query'
import TagManager from 'react-gtm-module'
import { SessionProvider } from 'next-auth/react'
import { Session } from 'next-auth'

declare global {
    interface Window {
        _submitForm: unknown
        _onloadCallback: unknown
        OneTrust?: OneTrust
        Optanon?: Optanon
        OnetrustActiveGroups?: string

        OptanonWrapper?: () => void
    }
}

interface Optanon {
    GetDomainData: () => {
        Groups: DomainGroup[]
    }
}
interface DomainGroup {
    CustomGroupId: string
    Cookies: { Name: string }[]
}

interface OneTrust {
    OnConsentChanged: (callback: () => void) => void
    ToggleInfoDisplay: () => void
    AllowAll: () => void
    TriggerGoogleAnalyticsEvent: (category: string, action: string) => void
    IsAlertBoxClosedAndValid: () => boolean
}

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
    P,
    IP
> & {
    getLayout: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps<{
    session: Session
}> & {
    Component: NextPageWithLayout
}

const tagManagerArgs = {
    gtmId: 'GTM-K3LQPX',
}

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
    const queryClient = new QueryClient()
    const getLayout = Component.getLayout ?? ((page) => page)

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
    }, [])

    return (
        <SessionProvider session={pageProps.session} refetchInterval={300}>
            {getLayout(
                <QueryClientProvider client={queryClient}>
                    <Component {...pageProps} />
                </QueryClientProvider>
            )}
        </SessionProvider>
    )
}
